<template>
	<div>
		<TableList :form_data_seo.sync="form_data_seo" :columns="columns" :get_table_list="this.get_table_list"
			:slot_table_list="['operation','status','num','outcome_id']" :where='where'  @changeStatus='changeStatus' :is_created_get_list="false"
			:submit_preprocessing="submit_preprocessing" ref="list">
			<template slot="form_after">
				<div class='page_content_top page_content'>
					<div v-for='item in tracing_count'>
						<a-statistic :title="item.label" :value="item.value" />
					</div>
				</div>
			
			</template>
			<template slot="num" slot-scope="data">
				<span>
					<a  @click="see_list(data.record)">{{data.record.num}}</a>
				</span>
			</template>
			<template slot="operation" slot-scope="data">
				<span>
					<a  @click="see_code(data.record)">二维码</a>
				</span>
			</template>
			<template slot="outcome_id" slot-scope="data">
				<span>
					<a  @click="go_list_detail(data.record)">{{data.record.outcome_id}}</a>
				</span>
			</template>
			
			
		</TableList>
		<!-- 扫码记录 -->
		<codeList ref="codeList" @submit="select_submit" :where='temp_where'  :visible.sync="list_visible"></codeList>
		<!-- 溯源二维码 -->
		<a-modal title="二维码" :maskClosable='true' :visible="visible" @cancel='visible=false' @ok='visible=false'>
			<div class='qrCodeUrlImg'>
				<div id='qrCodeUrl' ref="qrCodeUrl"></div>
			</div>
		</a-modal>
	</div>
</template>

<script>
	// @ is an alias to /src
	import EditPop from "@/components/EditPop";
	import Form from "@/components/Form";
	import TableList from "@/components/TableList";
	import codeList from "@/components/codeList";
	import QRCode from 'qrcodejs2'
	import {
		getTracingList,getFactorySelect,
		getTracingCount,
		TracingSave,
		getCodeDetail,
		getCodeCount,getWarehouseSelect
		
	} from "@/api/traceability";
	let columns = [{
			title: "溯源码",
			dataIndex: "source_code",
		},
		{
			title: "创建基地",
			dataIndex: "create_factory",
		},
		{
			title: "创建品项",
			dataIndex: "goods_name",
		},
		{
			title: "使用品项",
			dataIndex: "use_goods_name",
		},
		{
			title: "使用基地",
			dataIndex: "use_factory",
		},
		{
			title: "出库单ID",
			dataIndex: "outcome_id",
			
			scopedSlots: {
				customRender: "outcome_id"
			}
		},
		{
			title: "出库仓库",
			dataIndex: "warehouse_name",
		},

		{
			title: "经销商",
			dataIndex: "seller_name",

		},
		{
			title: "状态",
			dataIndex: "status_name",
		},
		{
			title: "被扫次数",
			dataIndex: "num",
			scopedSlots: {
				customRender: "num"
			}
		},
		{
			title: "生成时间",
			dataIndex: "create_time",

		},
		{
			title: "使用时间",
			dataIndex: "update_time",

		},
		{
			title: "操作",
			scopedSlots: {
				customRender: "operation"
			}
		},
	];

	export default {
		name: "gpoodIndex",
		components: {
			EditPop,
			Form,
			codeList,
			TableList,
		},
		data() {
			return {
				temp_where:{
					source_code:''
				},
				tracing_count: [],
				list_visible: false,
				get_table_list: getCodeDetail,
				form: this.$form.createForm(this, {
					name: "advanced_search"
				}),
				columns,
				visible: false,
				form_data_seo: {
					list: [{
							type: "text",
							name: "source_code",
							title: "溯源码",
							placeholder: '溯源码',
							options: {

							}
						},
						{
							type: "text",
							name: "outcome_id",
							title: "出库单ID",
							placeholder: '出库单ID',
							options: {

							}
						},
						{
							type: 'select',
							name: 'status',
							title: '状态',
							placeholder:'请选择',
							list: [
								{
									key: 0,
									value: "全部"
								},
								{
									key: 1,
									value: "未使用"
								},
								{
									key: 2,
									value: "已使用"
								}
							],
							options: {
								initialValue:''
							},
						},
						{
						    type: 'select',
						    name: 'create_factory',
							placeholder:'请选择创建基地',
						    title: '创建基地',
							list:[],
							options:{
								initialValue:''
							}
						    
						},
						{
						    type: 'select',
						    name: 'use_factory',
							placeholder:'请选择使用基地',
						    title: '使用基地',
							list:[],
							options:{
								initialValue:''
							}
						   
						},
						{
							type: "range_date",
							name: "create",
							title: "生成时间",
							options: {},
							start: {
								name: 'create_start'
							},
							end: {
								name: 'create_end'
							},
						},
						{
							type: "range_date",
							name: "use",
							title: "使用时间",
							options: {},
							start: {
								name: 'use_start'
							},
							end: {
								name: 'use_end'
							},
						},
						
						{
							type: "text",
							name: "use_goods_name",
							title: "使用品项",
							placeholder: '使用品项',
							options: {
						
							}
						},
						
					],
					...this.$config.form_data_seo
				},
				submit_preprocessing: {
					array_to_string: ['cate_id', 'department_ids', 'cost_department_ids']
				}
			};
		},
		async created() {
			this.where = this.$route.query;
			if(this.$route.query.status){
				this.where.status =this.$route.query.status
			}
			this.form_data_seo.list.forEach(item => {
				 if (item.name == 'use_factory') {
					item.options.initialValue =this.$route.query.factory_sap_id;
				}
				if (item.name == 'status') {
					item.options.initialValue =this.$route.query.status?Number(this.$route.query.status):0;
				}
				if (item.name == 'outcome_id') {
					item.options.initialValue = this.$route.query.outcome_id;
				}
			})
			this.where.use_factory =this.$route.query.factory_sap_id;
			
			this.$nextTick(()=>{
				this.$refs.list.get_list();
			})
			this.get_Code_Count()
			this.get_info()
		},
		methods: {
			go_list_detail(e){
				this.$router.push({
					path: "/send/details",
					query: {
						id: e.delivery_id,
					}
				});
			},
			
			get_info(){
				// 基地数据
				getFactorySelect().then(res=>{
					this.$method.set_form_list(this.form_data_seo.list,'create_factory','list',res.data.list);
					this.$method.set_form_list(this.form_data_seo.list,'use_factory','list',res.data.list);
				})
				
			},
			changeStatus(data){
				this.get_Code_Count(data)
			},
			get_Code_Count(data) {
				getCodeCount({data:{
					...this.where,
					...data
				}}).then(res => {
					this.tracing_count = res.data.list
				})
			},
			select_submit() {

			},
			// 查看扫码记录
			see_list(e) {
				this.list_visible = true;
				this.temp_where={
					source_code:e.source_code,
				}
					
			},
			// 查看溯源码
			see_code(data) {
				this.visible = true
				this.$nextTick(res => {
					this.creatQrCode(data)
				})
			},
			// 生成二维码
			creatQrCode(data) {
				document.getElementById("qrCodeUrl").innerHTML = "";
				let qrcode = new QRCode('qrCodeUrl', {
					width: 240,
					height: 240,
					text: data.url, // 二维码内容  
					correctLevel: QRCode.CorrectLevel.H,
					render: 'canvas' // 设置渲染方式（有两种方式 table和canvas，默认是canvas）  
				})
			},
			// 保存商品预警设置
			max_price_submit() {
				saveSetting({
					data: {
						data: this.max_price_form, // 我也不想这么做，但因为后端原因我必须在外面包一层data
					},
					info: true
				}).then(res => {
					this.max_price_visible = false;
				})
			},

		}
	};
</script>

<style lang="less">
	@import url("../../../assets/less/app.less");

	.max_price_tips {
		margin-left: 99px;
		margin-top: -30px;
		font-size: 13px;
	}

	.float_left {
		margin-right: 8px;
	}

	.max_price_form {
		.item {
			display: flex;

			.right {
				flex: 1;

				.input_number {
					width: 100%;
				}
			}
		}
	}

	.qrCodeUrlImg {
		margin-left: 110px;
	}

	.page_content_top {
		display: flex;

		div {
			flex: 1;
			text-align: center;
		}
	}
</style>